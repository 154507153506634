import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import VerticalMenu from "./VerticalMenu";
import "./Menu.css";
import { useAuthContext } from "../redux/auth-context";

const Menu = (props) => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [selectedCountry, setSelectedCountry] = useState({
    key: "usa",
    name: "United States of America",
  });
  const [selectedState, setSelectedState] = useState();

  return (
    <div className="d-none d-xl-block container">
      <div className="row">
        <div className="col-md-auto d-none d-xl-block">
          <div className="max-width-270 min-width-270">
            <VerticalMenu {...props} />
          </div>
        </div>
        <div className="col">
          <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
            <div
              id="navBar"
              className="collapse navbar-collapse u-header__navbar-collapse"
            >
              <ul className="navbar-nav u-header__navbar-nav">
                <li className="nav-item u-header__nav-item">
                  <Link className="nav-link u-header__nav-link" to="/">
                    Home
                  </Link>
                </li>

                <li
                  className="nav-item hs-has-mega-menu u-header__nav-item locations-menu"
                  data-event="click"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  data-position="left"
                >
                  <a
                    id="homeMegaMenu"
                    className="nav-link u-header__nav-link u-header__nav-link-toggle"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Locations
                  </a>

                  <div
                    className="hs-mega-menu w-100 u-header__sub-menu locations-sub-menu custom-scroll"
                    aria-labelledby="homeMegaMenu"
                  >
                    <div className="row u-header__mega-menu-wrapper">
                      <div className="col pr-0 pl-0">
                        <ul className="u-header__sub-menu-nav-group mb-1">
                          {ctx.menuData?.countries &&
                            ctx.menuData?.countries?.map((item) => {
                              return (
                                <li
                                  key={item.key}
                                  className={
                                    item.key == selectedCountry?.key
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  <a
                                    href="#"
                                    className="nav-link u-header__sub-menu-nav-link link-toggle"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedCountry(item);
                                    }}
                                  >
                                    {item.name}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="col pl-0 pr-0 bdr-left">
                        {selectedCountry && ctx.menuData?.states && (
                          <>
                            <ul className="u-header__sub-menu-nav-group">
                              <li className="selected">
                                <a
                                  href="#"
                                  className="nav-link u-header__sub-menu-nav-link link-toggle"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  States
                                </a>
                              </li>
                            </ul>

                            <ul className="u-header__sub-menu-nav-group mb-3 states">
                              {ctx.menuData?.states
                                .filter(
                                  (item) => item.country == selectedCountry.key
                                )
                                .map((item) => {
                                  return (
                                    <li
                                      key={item.id}
                                      className={
                                        selectedState?.id == item.id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      <a
                                        href="#"
                                        className="nav-link u-header__sub-menu-nav-link"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSelectedState(item);
                                        }}
                                      >
                                        {item.name}
                                      </a>
                                    </li>
                                  );
                                })}
                            </ul>
                          </>
                        )}
                      </div>
                      <div className="col-5 pr-0 pl-0 bdr-left">
                        {selectedCountry &&
                          selectedState &&
                          ctx.menuData?.cities && (
                            <>
                              <ul className="u-header__sub-menu-nav-group">
                                <li className="selected">
                                  <a
                                    href="#"
                                    className="nav-link u-header__sub-menu-nav-link"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    Cities
                                  </a>
                                </li>
                              </ul>

                              <ul className="u-header__sub-menu-nav-group mb-3 cities ml-1">
                                {ctx.menuData?.cities
                                  .filter(
                                    (item) => item.state == selectedState.id
                                  )
                                  .map((item) => {
                                    return (
                                      <li key={item.id}>
                                        <a
                                          href="#"
                                          className="nav-link u-header__sub-menu-nav-link"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/search-result", {
                                              state: {
                                                filter: item.id
                                                  ? "?city=" +
                                                    item.id +
                                                    "&state=" +
                                                    item.state
                                                  : "",
                                              },
                                            });
                                          }}
                                        >
                                          {item.name}
                                        </a>
                                      </li>
                                    );
                                  })}

                                <li>
                                  <a
                                    href="#"
                                    className="nav-link u-header__sub-menu-nav-link secondary-500"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate("/search-result", {
                                        state: {
                                          filter: "?state=" + selectedState.id,
                                        },
                                      });
                                    }}
                                  >
                                    View All {selectedState.name}
                                  </a>
                                </li>
                              </ul>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item u-header__nav-item">
                  <a
                    className="nav-link u-header__nav-link"
                    href="#"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/search-result", {
                        state: {
                          filter: "?badge=popular_auctions",
                        },
                      });
                    }}
                  >
                    Popular Auctions
                  </a>
                </li>

                <li className="nav-item u-header__nav-item">
                  <a
                    className="nav-link u-header__nav-link"
                    href="#"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/search-result", {
                        state: {
                          filter: "?badge=ending_soon",
                        },
                      });
                    }}
                  >
                    Closing Soon
                  </a>
                </li>

                <li className="nav-item u-header__nav-item">
                  <a
                    className="nav-link u-header__nav-link"
                    href="#"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="blogSubMenu"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/search-result", {
                        state: {
                          filter: "?badge=new_auctions",
                        },
                      });
                    }}
                  >
                    New Listings
                  </a>
                </li>

                <li className="nav-item u-header__nav-last-item">
                  <Link
                    className="nav-link u-header__nav-link"
                    to="/categories"
                  >
                    View All
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Menu;
