import { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import AuctionBlock from "../../components/auction/AuctionBlock";
import Breadcrumb from "../../components/common/Breadcrumb";
import BreadcrumbItem from "../../components/common/BreadcrumbItem";
import {
  ITEM_URL,
  NEWLY_ADDED_AUCTIONS_URL,
  POPULAR_AUCTIONS_URL,
} from "../../Constants";
import Layout from "../../layout/Layout";
import { LibraryService } from "../../services/LibraryService";
import AuctionsPanel from "../home/AuctionsPanel";
import ZImageCarousal from "./ZImageCarousal";
import classes from "./Auction.module.css";
import clock from "../../assets/images/search-clock.svg";
import LinkButton from "../../components/button/LinkButton";
import TitleUnderline from "../../components/common/TitleUnderline";
import { ItemService } from "../../services/SearchService";
import Map from "./Map";
import { useAuthContext } from "../../redux/auth-context";
import { da } from "date-fns/locale";
import Question from "./Question";
import { userAddQuestion } from "../../services/AuctionService";
import { shallowEqual, useSelector } from "react-redux";
import AuctionBid from "./AuctionBid";
import MyTimer from "./MyTimer";
import BuyNow from "./BuyNow";
import { isSeller } from "../../services/Utilities";

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const SingleAunctionItem = () => {
  const location = useLocation();
  const ctx = useAuthContext();
  const bidHistoryRef = useRef(null);
  const navigate = useNavigate();
  const [popularAuctions, setPopularAuctions] = useState([]);
  const [newAuctions, setNewAuctions] = useState([]);
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(true);
  const [questionBox, setQuestionBox] = useState(false);
  const [showMore, setShowMore] = useState(false);
  let params = useParams();

  const { isAuthorized, viewExpired, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      viewExpired: auth.viewExpired,
      user: auth.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    LibraryService(POPULAR_AUCTIONS_URL)
      .then((res) => {
        setPopularAuctions(res);
      })
      .catch((e) => {
        navigate("/error");
      });
    LibraryService(NEWLY_ADDED_AUCTIONS_URL)
      .then((res) => {
        setNewAuctions(res);
      })
      .catch((e) => {
        navigate("/error");
      });

    fetchItemDetails(params.item);
  }, [params.item]);

  const fetchItemDetails = (itemId) => {
    ctx.showProgress(true);
    ItemService(ITEM_URL + itemId, isAuthorized)
      .then((data) => {
        console.log(data);
        setItem(data);
        setLoading(false);
        ctx.showProgress(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setItem(null);
        ctx.showProgress(false);
      });
  };

  const askQuestion = () => {
    if (isAuthorized) {
      setQuestionBox(true);
    } else {
      navigate("/login", { state: { location } });
    }
  };

  const onQuestionPosted = (question) => {
    console.log(question, item.itemId);
    ctx.showProgress(true);
    userAddQuestion({
      itemId: Number(item.itemId),
      question: question.question,
    })
      .then((data) => {
        console.log(data);
        ctx.showProgress(false);
        setQuestionBox(false);
        fetchItemDetails(item.itemId);
      })
      .catch((error) => {
        console.log(error);
        ctx.showProgress(false);
        setQuestionBox(false);
      });
  };

  const navigateToSearch = (sellerId) => {
    console.log(sellerId);
    navigate("/search-result", {
      state: { filter: "?seller=" + sellerId },
    });
  };

  const time = new Date();
  time.setSeconds(time.getSeconds() + item?.endsInSeconds); // 10 minutes timer

  const HistoryTable = ({ history, idx }) => {
    return (
      <tr>
        <td>
          <label className={classes.additionalInfoListItem}>{idx + 1}</label>
        </td>
        <td>
          <label className={classes.additionalInfoListItem}>
            {history.bidder}
          </label>
        </td>
        <td>
          <label className={classes.additionalInfoListItem}>
            {history.bidAmount} {item.currency}
          </label>
        </td>
        <td>
          <label className={classes.additionalInfoListItem}>
            {history.dateTime}
          </label>
        </td>
      </tr>
    );
  };

  const scrollToBI = () => {
    const section = document.querySelector("#bidHistoryRef");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollToSellerTerms = () => {
    navigate("/seller-terms", { state: { term: item.sellerTerms } });
    //const section = document.querySelector("#sellerTerms");
    //section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        {/* <div className="mb-3">
          <Breadcrumb
            items={[
              <BreadcrumbItem key={1} title="Home" to="/" />,
              <BreadcrumbItem key={2} title={item?.category} />,
              <BreadcrumbItem key={3} title={item?.subCategory} />,
              <BreadcrumbItem key={3} title={`LOT # ${item?.cutomId}`} />,
            ]}
          />
        </div> */}

        {item ? (
          <>
            <div className="row my-0">
              <div className="col-md-6 mb-5">
                <ZImageCarousal items={item.mediaList} />
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <label className={classes.actionTypeTitle}>
                      {`LOT # ${item.cutomId}`}
                    </label>
                  </div>
                  <div className="col-md-12 mb-2">
                    <label className={classes.actionTitle}>{item.title}</label>
                  </div>
                  {item.saleType == "ONLINE_AUCTION" && (
                    <div className="col-md-12">
                      <label className={classes.auctionLabelEnd}>
                        <Link
                          onClick={() => {
                            fetchItemDetails(item.itemId);
                          }}
                        >
                          {" "}
                          <i class="fa fa-redo" aria-hidden="true"></i>{" "}
                        </Link>
                        {item.noOfBids > 0 && isAuthorized ? (
                          <Link
                            onClick={() => {
                              scrollToBI();
                            }}
                          >
                            {item.noOfBids} Bids
                          </Link>
                        ) : (
                          `${item.noOfBids} Bids`
                        )}{" "}
                        | {item.endIn}
                      </label>
                    </div>
                  )}
                  {item.saleType == "BUY_NOW" && (
                    <>
                      <div className="col-md-12">
                        <label
                          className={`${classes.auctionLocationLabel} mb-0`}
                        >
                          Price:
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className={`${classes.actionBiddingValue} mb-0`}>
                          ${item.currentMaxBid}
                        </label>
                      </div>
                    </>
                  )}
                  {item.saleType != "BUY_NOW" &&
                    item.saleType != "MAKE_AN_OFFER" && (
                      <div className="col-md-12 my-1">
                        {item.currentMaxBid >= item.reservePrice ||
                        item.startingPrice >= item.reservePrice ? (
                          item.noOfBids > 0 && (
                            <>
                              <label
                                className={`${classes.auctionLocationValues} mb-0 badge bg-success-2`}
                                style={{
                                  color: "#fff",
                                  backgroundColor: "#339966 !important",
                                }}
                              >
                                Reserve Met
                              </label>
                            </>
                          )
                        ) : (
                          <>
                            <label
                              className={`${classes.auctionLocationValues} mb-0 badge bg-danger`}
                            >
                              Reserve not Met
                            </label>
                          </>
                        )}
                      </div>
                    )}
                  {item.saleType == "ONLINE_AUCTION" && (
                    <>
                      <div className="col-md-12">
                        <label
                          className={`${classes.auctionLocationLabel} mb-0`}
                        >
                          Current Price:
                        </label>
                      </div>

                      <div className="col-md-12 mb-1">
                        <label className={`${classes.actionBiddingValue} mb-0`}>
                          $
                          {item.currentMaxBid > 0
                            ? item.currentMaxBid
                            : item.startingPrice}{" "}
                          {item.currency}
                        </label>
                      </div>

                      <div className="col-md-12">
                        <label
                          className={`${classes.auctionLocationLabel} mb-0`}
                        >
                          Bid Increment:
                        </label>
                      </div>
                      <div className="col-md-12 mb-1">
                        <label className={classes.actionBiddingValue}>
                          ${item.bidIncrement} {item.currency}
                        </label>
                      </div>
                    </>
                  )}
                  <div className="col-md-12">
                    <label className={`${classes.auctionLocationLabel} mb-0`}>
                      Sales Type / Lot Type:
                    </label>
                  </div>
                  <div className="col-md-12">
                    <label className={classes.auctionLocationValues}>
                      {item.saleType.replace(/_/g, " ").toProperCase()}
                    </label>
                  </div>
                  <div className="col-md-12">
                    <label className={`${classes.auctionLocationLabel} mb-0`}>
                      Item Condition:
                    </label>
                  </div>
                  <div className="col-md-12">
                    <label className={classes.auctionLocationValues}>
                      {item.condition.replace(/_/g, " ").toProperCase()}
                    </label>
                  </div>
                  <div className="col-md-12">
                    <label className={`${classes.auctionLocationLabel} mb-0`}>
                      Location:
                    </label>
                  </div>
                  <div className="col-md-12 mb-2">
                    <label className={classes.auctionLocationValues}>
                      {item.citiName}, {item.stateName}, {item.countryName}
                    </label>
                  </div>

                  {item.maxMidYouP && item.saleType == "ONLINE_AUCTION" && (
                    <div className="col-md-12 mb-2 text-center">
                      <label
                        className={`${classes.auctionLocationLabel} mb-0 b px-2 badge bg-success-2 ${classes.infoTableValueGreen}`}
                      >
                        You are the highest Bidder
                      </label>
                    </div>
                  )}

                  {(item.saleType == "ONLINE_AUCTION" ||
                    item.saleType == "SEALED_AUCTION" ||
                    item.saleType == "MAKE_AN_OFFER") && (
                    <>
                      <div className="col-md-12 mb-2 text-center">
                        <label
                          className={`${classes.auctionLocationLabel} mb-0`}
                        >
                          This Auction Ends in
                        </label>
                      </div>
                      <div className="col-md-12 mb-2 d-flex justify-content-center">
                        <MyTimer expiryTimestamp={time} />
                      </div>
                    </>
                  )}
                  {item.saleType == "BUY_NOW" ? (
                    !isSeller(user.type) ? (
                      <BuyNow
                        isAuthorized={isAuthorized}
                        item={item}
                        reFetchDetail={() => {
                          fetchItemDetails(item.itemId);
                        }}
                      />
                    ) : (
                      <div
                        className={`${classes.auctionLocationValues} col-md-12 mb-2 text-center`}
                      >
                        <p className="text-bold text-center">
                          Login with Bidder Account to Bid
                        </p>
                      </div>
                    )
                  ) : (
                    <AuctionBid
                      isAuthorized={isAuthorized}
                      item={item}
                      reFetchDetail={() => {
                        fetchItemDetails(item.itemId);
                      }}
                    />
                  )}
                  <div className="col-md-12 mt-4">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td colSpan={2} className="p-0 text-center">
                            <label
                              className={`${classes.auctionLocationLabel}`}
                            >
                              {item.watchCount > 0 &&
                                `Watchers : ${item.watchCount} | `}{" "}
                              Visitors: {item.view}
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="p-0">
                            <label className={classes.infoTableLabel}>
                              Taxes
                            </label>
                          </td>
                          <td className="p-0">
                            <label className={classes.infoTableValue}>
                              To be added at payment
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="p-0">
                            <label className={classes.infoTableLabel}>
                              Buyer's Premium
                            </label>
                          </td>
                          <td className="p-0">
                            <label className={classes.infoTableValue}>
                              {item.buyerPrimium}%
                            </label>
                          </td>
                        </tr>
                        {item.saleType == "ONLINE_AUCTION" && item.history?.length > 0 && (
                          <tr>
                            <td className="p-0">
                              <label className={classes.infoTableLabel}>
                                High Bidder
                              </label>
                            </td>
                            <td className="p-0">{item.history[0]?.bidder}</td>
                          </tr>
                        )}
                        <tr>
                          <td className="p-0">
                            <label className={classes.infoTableLabel}>
                              Seller
                            </label>
                          </td>
                          <td className="p-0">
                            <label className={classes.infoTableValue}>
                              {item.sellerName}
                            </label>

                            {item?.sellerDetails?.sellerLogo && (
                              <>
                                <br />
                                <img
                                  src={item?.sellerDetails?.sellerLogo}
                                  className="seller-logo mb-1"
                                />
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-0">
                            <label className={classes.infoTableLabel}>
                              Account Type
                            </label>
                          </td>
                          <td className="p-0">
                            <label className={classes.infoTableValue}>
                              {item.sellerType}
                            </label>
                          </td>
                        </tr>

                        {item?.sellerTerms && (
                          <tr>
                            <td className="p-0">
                              <label className={classes.infoTableLabel}>
                                Seller's Terms & Conditions
                              </label>
                            </td>
                            <td className="p-0">
                              {/* <button
                                className={classes.infoTableValueGreen}
                                onClick={(e) => {
                                  scrollToSellerTerms();
                                }}
                              >
                                View
                              </button> */}

                              <Link
                                className={classes.infoTableValueGreen}
                                to={"/seller-terms/" + item.itemId}
                                target="_blank"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="p-0">
                            <label className={classes.infoTableLabel}>
                              Seller's Other Auctions
                            </label>
                          </td>
                          <td className="p-0">
                            <button
                              onClick={() => navigateToSearch(item.sellerId)}
                              className={classes.infoTableValueGreen}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="offset-lg-2 offset-md-2  col-lg-8 col-md-8">
                <div className="row mt-4">
                  <div className="col-md-12">
                    <h2 className={classes.title}>Item Description</h2>
                    <TitleUnderline />
                    <p className={`${classes.description}`}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    </p>
                  </div>
                  {isAuthorized && item?.vehicleInfo && (
                    <div className="col-md-12 mb-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label className={classes.infoTableLabel}>
                            Make:
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className={classes.infoTableLabelBlue}>
                            {item.vehicleInfo.make}
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label className={classes.infoTableLabel}>
                            Model:
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className={classes.infoTableLabelBlue}>
                            {item.vehicleInfo.model}
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label className={classes.infoTableLabel}>
                            Miles:
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className={classes.infoTableLabelBlue}>
                            {item.vehicleInfo.miles}
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label className={classes.infoTableLabel}>
                            Year:
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className={classes.infoTableLabelBlue}>
                            {item.vehicleInfo.year}
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label className={classes.infoTableLabel}>
                            Vehicle has transferable title:
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className={classes.infoTableLabelBlue}>
                            {item.vehicleInfo.transferrableTitle}
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label className={classes.infoTableLabel}>VIN:</label>
                        </div>
                        <div className="col-md-8">
                          <label className={classes.infoTableLabelBlue}>
                            {item.vehicleInfo.vin}
                          </label>
                        </div>

                        <div className="col-md-4">
                          <label className={classes.infoTableLabel}>
                            Does It Start:
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className={classes.infoTableLabelBlue}>
                            {item.vehicleInfo.starts}
                          </label>
                        </div>

                        <div className="col-md-4">
                          <label className={classes.infoTableLabel}>
                            It is lot tested:
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className={classes.infoTableLabelBlue}>
                            {item.vehicleInfo.derivableOffLot}
                          </label>
                        </div>

                        <div className="col-md-4">
                          <label className={classes.infoTableLabel}>
                            Vehicle has a transferable title:
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className={classes.infoTableLabelBlue}>
                            {item.vehicleInfo.transferrableTitle}
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {item.mediaList &&
                    item.mediaList.length > 0 &&
                    item.mediaList.filter((media) => {
                      return media.mediaType == "DOCUMENT";
                    }).length > 0 && (
                      <div className="col-md-12 mb-4">
                        <h2 className={classes.title}>Documents</h2>
                        <TitleUnderline />
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12">
                            <table className="table">
                              <tr>
                                <th></th>
                                <th>File Name</th>
                                <th></th>
                              </tr>
                              {item.mediaList
                                .filter((media) => {
                                  return media.mediaType == "DOCUMENT";
                                })
                                .map((media, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <label
                                          className={
                                            classes.additionalInfoListItem
                                          }
                                        >
                                          {idx + 1}
                                        </label>
                                      </td>
                                      <td>
                                        <label
                                          className={
                                            classes.additionalInfoListItem
                                          }
                                        >
                                          {media.fileTitle}{" "}
                                        </label>
                                      </td>
                                      <td>
                                        <label
                                          className={
                                            classes.additionalInfoListItem
                                          }
                                        >
                                          <a href={media.link} download>
                                            {"Download"}
                                          </a>
                                        </label>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  <div className="col-md-12">
                    <h2 className={classes.title}>Q & A</h2>
                    <TitleUnderline />
                    {item.questions && item.questions.length > 0 ? (
                      <>
                        {item.questions.map((question) => {
                          return (
                            <dl className="my-4">
                              <dt className={classes.question}>
                                {question.question} <br />
                                <span className={classes.timestamp}>
                                  Asked at {question.askedAt}
                                </span>
                              </dt>
                              {question.answer && (
                                <dd className={classes.answer}>
                                  {question.answer}
                                  <br />
                                  {/* <span className={classes.timestamp}>
                                    Answered on {question.repliedOn}
                                  </span> */}
                                </dd>
                              )}
                            </dl>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <p className="my-2">No information available</p>
                      </>
                    )}
                  </div>
                  {!isSeller(user.type) && (
                    <div className="col-md-12 mb-4">
                      <p className="my-1">
                        Have a question or looking to schedule an appointment?
                      </p>
                      <button
                        onClick={() => {
                          askQuestion();
                        }}
                        className={`btn btn-outline-secondary`}
                      >
                        Ask your question
                      </button>
                    </div>
                  )}

                  {questionBox && (
                    <Question
                      onQuestionPosted={onQuestionPosted}
                      onClose={() => {
                        setQuestionBox(false);
                      }}
                    />
                  )}

                  {isAuthorized && item?.sellerDetails && (
                    <div className="col-md-12 mb-4 mt-2">
                      <h2 className={classes.title}>Seller Information</h2>
                      <TitleUnderline />
                      <div className="row my-4">
                        <div className="col-md-3">
                          <label className={classes.infoTableLabel}>
                            Seller:
                          </label>
                        </div>
                        <div className="col-md-9">
                          <label className={classes.infoTableLabelBlue}>
                            {item.sellerName}
                          </label>
                        </div>
                        <div className="col-md-3">
                          <label className={classes.infoTableLabel}>
                            Email:
                          </label>
                        </div>
                        <div className="col-md-9">
                          <label className={classes.infoTableLabelBlue}>
                            {item.sellerDetails.email}
                          </label>
                        </div>
                        <div className="col-md-3">
                          <label className={classes.infoTableLabel}>
                            Item Location:
                          </label>
                        </div>
                        <div className="col-md-9">
                          <label className={classes.infoTableLabelBlue}>
                            {item.citiName}, {item.stateName},{" "}
                            {item.countryName}
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {item?.inspectionContactName &&
                    item?.inspectionContactNumber && (
                      <div className="col-md-12 mb-4">
                        <h2 className={classes.title}>Inspection</h2>
                        <TitleUnderline />
                        <div className="row my-4">
                          <div className="col-md-3">
                            <label className={classes.infoTableLabel}>
                              Contact Name:
                            </label>
                          </div>
                          <div className="col-md-9">
                            <label className={classes.infoTableLabelBlue}>
                              {item.inspectionContactName}
                            </label>
                          </div>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-3">
                            <label className={classes.infoTableLabel}>
                              Contact Number:
                            </label>
                          </div>
                          <div className="col-md-9">
                            <label className={classes.infoTableLabelBlue}>
                              {item.inspectionContactNumber}
                            </label>
                          </div>
                        </div>
                        {item.inspectionInformation && (
                          <>
                            <div className="row my-4">
                              <div className="col-md-3">
                                <label className={classes.infoTableLabel}>
                                  Inspection Instructions:
                                </label>
                              </div>
                              <div className="col-md-9">
                                <label className={classes.infoTableLabelBlue}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.inspectionInformation,
                                    }}
                                  />
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}

                  {/* {item?.inspectionInformation && (
                    <div className="col-md-12 mb-4">
                      <h2 className={classes.title}>Inspection</h2>
                      <TitleUnderline />
                      <p className={`${classes.inspectionParagraph} my-4`}>
                        {item?.inspectionInformation}
                      </p>
                    </div>
                  )} */}

                  {item?.paymentDescription && (
                    <div className="col-md-12 mb-4">
                      <h2 className={classes.title}>Payment</h2>
                      <TitleUnderline />
                      <p className={`${classes.paymentParagraph} my-4`}>
                        PAYMENT
                      </p>
                      <p className={`${classes.inspectionParagraph} my-2`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.paymentDescription,
                          }}
                        />
                      </p>
                    </div>
                  )}

                  {item?.removalInformation && (
                    <div className="col-md-12 mb-4">
                      <h2 className={classes.title}>Removal</h2>
                      <TitleUnderline />
                      <p className={`${classes.inspectionParagraph} my-4`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.removalInformation,
                          }}
                        />
                      </p>
                    </div>
                  )}

                  {item?.specialInstructions && (
                    <div className="col-md-12 mb-4">
                      <h2 className={classes.title}>Special Instructions</h2>
                      <TitleUnderline />
                      <p className={`${classes.inspectionParagraph} my-4`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.specialInstructions,
                          }}
                        />
                      </p>
                    </div>
                  )}

                  {/* {item?.sellerTerms && (
                    <div className="col-md-12 mb-4">
                      <h2 className={classes.title} id="sellerTerms">
                        Seller Terms & Conditions
                      </h2>
                      <TitleUnderline />
                      <p className={`${classes.inspectionParagraph} my-4`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.sellerTerms,
                          }}
                        />
                      </p>
                    </div>
                  )} */}

                  <div className="col-md-12 mb-4">
                    <h2 className={classes.title}>Additional Information</h2>
                    <TitleUnderline />
                    {item?.additionalInformation && (
                      <p className={`${classes.inspectionParagraph} my-4`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.additionalInformation,
                          }}
                        />
                      </p>
                    )}
                    <p className={`${classes.inspectionParagraph} my-4`}>
                      First time bidding? Please review our{" "}
                      <Link to="/terms-of-use">Terms and Conditions.</Link>
                    </p>
                    <p className={`${classes.inspectionParagraph} my-2`}>
                      For further assistance please review our{" "}
                      <Link to="/article/BIDDER">
                        Frequently Asked Questions.
                      </Link>
                    </p>
                  </div>

                  {item.saleType == "ONLINE_AUCTION" &&
                    isAuthorized &&
                    item.history?.length > 0 && (
                      <div id="bidHistoryRef" className="col-md-12 mb-4">
                        <h2 className={classes.title}>Bid History</h2>
                        <TitleUnderline />
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xm-12">
                            <table className="table mb-0">
                              <tr>
                                <th></th>
                                <th>User ID</th>
                                <th>Bid Amount</th>
                                <th>Bid Date & Time</th>
                              </tr>
                              {showMore
                                ? item.history.map((history, idx) => {
                                    return (
                                      <HistoryTable
                                        history={history}
                                        idx={idx}
                                      />
                                    );
                                  })
                                : item.history
                                    .slice(0, 5)
                                    .map((history, idx) => {
                                      return (
                                        <HistoryTable
                                          history={history}
                                          idx={idx}
                                        />
                                      );
                                    })}
                            </table>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xm-12 text-right">
                            <Link onClick={() => setShowMore(!showMore)}>
                              {showMore ? "Show Less" : "Show More"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  {item &&
                    item.pickupLocationPublic &&
                    item.pickUpLat &&
                    item.pickUpLong && (
                      <div className="col-md-12 mb-4">
                        <h2 className={classes.title}>Item Location</h2>
                        <TitleUnderline />
                        {item.pickUpLocation && (
                          <div className="row my-4">
                            <div className="col-md-3">
                              <label className={classes.infoTableLabel}>
                                Pickup Location Details:
                              </label>
                            </div>
                            <div className="col-md-9">
                              <label className={classes.infoTableLabelBlue}>
                                {item.pickUpLocation}
                              </label>
                            </div>
                            <div className="col-md-3">
                              <label className={classes.infoTableLabel}>
                                Country:
                              </label>
                            </div>
                            <div className="col-md-9">
                              <label className={classes.infoTableLabelBlue}>
                                {item.countryName}
                              </label>
                            </div>
                            <div className="col-md-3">
                              <label className={classes.infoTableLabel}>
                                State:
                              </label>
                            </div>
                            <div className="col-md-9">
                              <label className={classes.infoTableLabelBlue}>
                                {item.stateName}
                              </label>
                            </div>
                            <div className="col-md-3">
                              <label className={classes.infoTableLabel}>
                                City:
                              </label>
                            </div>
                            <div className="col-md-9">
                              <label className={classes.infoTableLabelBlue}>
                                {item.citiName}
                              </label>
                            </div>
                          </div>
                        )}
                        <div className="mt-2">
                          <Map item={item} />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </>
        ) : !loading ? (
          <div className="row my-0">
            <div
              className="col-md-12 mb-5 text-center pt-10"
              style={{ minHeight: "200px" }}
            >
              <h2>Auction details not found</h2>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row mt-5">
          {popularAuctions && popularAuctions.length > 0 && (
            <div className="col-md-12">
              <AuctionsPanel
                title="Popular Auctions"
                viewAllClicked={() => {
                  navigate("/search-result", {
                    state: { filter: "?badge=popular_auctions" },
                  });
                }}
              >
                {popularAuctions.map((item, i) => {
                  return (
                    i < 4 && (
                      <AuctionBlock
                        key={i}
                        auctionType={item.type
                          .replaceAll("_", " ")
                          .toLowerCase()}
                        heading={item.title}
                        description={`${item.city}, ${item.state}`}
                        price={item.price}
                        image={item.defaultImage}
                        itemId={item.itemId}
                        time={item.endingIn}
                        lot={`LOT # ${item.customId}`}
                      />
                    )
                  );
                })}
              </AuctionsPanel>
            </div>
          )}
        </div>
        <div className="row mt-5">
          {newAuctions && newAuctions.length > 0 && (
            <div className="col-md-12">
              <AuctionsPanel
                title="Newly Added Auctions"
                viewAllClicked={() => {
                  navigate("/search-result", {
                    state: { filter: "?badge=new_auctions" },
                  });
                }}
              >
                {newAuctions.map((item, i) => {
                  return (
                    i < 4 && (
                      <AuctionBlock
                        key={i}
                        auctionType={item.type
                          .replaceAll("_", " ")
                          .toLowerCase()}
                        heading={item.title}
                        description={`${item.city}, ${item.state}`}
                        price={item.price}
                        image={item.defaultImage}
                        itemId={item.itemId}
                        time={item.endingIn}
                        lot={`LOT # ${item.customId}`}
                      />
                    )
                  );
                })}
              </AuctionsPanel>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SingleAunctionItem;
