import "./App.css";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Home from "./pages/home/Home";
import Register from "./pages/registration/Register";
import AddCard from "./pages/credit-card/AddCard";
import AddCardStatus from "./pages/credit-card/AddCardStatus";
import VerifyIdentityWelcome from "./pages/registration/VerifyIdentityWelcome";
import VerifyIdentityInstruction from "./pages/registration/VerifyIdentityInstruction";
import AccountInformation from "./pages/profile-update/AccountInformation";
import VerifyIdentity from "./pages/registration/VerifyIdentity";
import Login from "./pages/login/login";
import SearchResult from "./pages/search/SearchResult";
import AdvanceSearch from "./pages/search/AdvanceSearch";
import AccountType from "./pages/registration/AccountType";
import PersonalInformation from "./pages/registration/PersonalInformation";
import UserNotified from "./pages/registration/UserNotified";
import RegistrationSuccessfull from "./pages/registration/RegistrationSuccessfull";
import VerifyLink from "./pages/registration/VerifyLink";
import ForgotPassword from "./pages/forgot-password/forgotpassword";
import ResetPassword from "./pages/forgot-password/ResetPassword";
import VerifyEmail from "./pages/forgot-password/VerifyEmail";
import VerifyLinkFP from "./pages/forgot-password/VerifyLinkFP";
import AddCardBillingInfo from "./pages/credit-card/AddCardBillingInfo";
import AddCardInfo from "./pages/credit-card/AddCardInfo";
import AllCategories from "./pages/categories/AllCategories";
import { useAuthContext } from "./redux/auth-context";
import { useEffect } from "react";
import LoadingModal from "./components/UI/LoadingModal";
import { shallowEqual, useSelector } from "react-redux";
import ErrorPage from "./pages/errors/ErrorPage";
import Error404 from "./pages/errors/Error404";
import NotAuthorized from "./pages/errors/NotAuthorized";
import ViewExpired from "./pages/errors/ViewExpired";
import CyberAttack from "./pages/errors/CyberAttack";
import Logout from "./pages/login/logout";
import AddAddressInfo from "./pages/profile-update/AddAddressInfo";
import Loader from "../src/assets/images/loader.gif";
import AddAddress from "./pages/profile-update/AddAddress";
import AddPhone from "./pages/profile-update/AddPhone";
import AddPhoneInfo from "./pages/profile-update/AddPhoneInfo";
import SingleAunctionItem from "./pages/auction/SingleAunctionItem";
import AunctionItemPublicView from "./pages/auction/AunctionItemPublicView";
import {
  CATEGORY_MENU_URL,
  CITY_ITEM_COUNT_LIBRARY_URL,
  COUNTRY_ITEM_COUNT_LIBRARY_URL,
  STATE_ITEM_COUNT_LIBRARY_URL,
  LIVE_CHAT_LICENSE,
  TIMEZONE_NAME,
} from "./Constants";
import { LibraryService } from "./services/LibraryService";
import OpenBids from "./pages/my-bids/OpenBids";
import BidsWon from "./pages/my-bids/BidsWon";
import BidsLost from "./pages/my-bids/BidsLost";
import CheckoutSelection from "./pages/my-bids/CheckoutSelection";
import WireTransfer from "./pages/my-bids/WireTransfer";
import ResetPasswordPU from "./pages/profile-update/ResetPasswordPU";
import CheckoutCardBillingInfo from "./pages/my-bids/CheckoutCardBillingInfo";
import CheckoutCardPaymentInfo from "./pages/my-bids/CheckoutCardPaymentInfo";
import CardPaymentStatusMessage from "./pages/my-bids/CardPaymentStatusMessage";
import CardPaymentCompleted from "./pages/my-bids/CardPaymentCompleted";
import UploadWireReceipt from "./pages/my-bids/UploadWireReceipt";
import WishList from "./pages/profile-update/WishList";
import WirePaymentStatusMessage from "./pages/my-bids/WirePaymentStatusMessage";
import OpenOffers from "./pages/my-bids/OpenOffers";
import OffersLost from "./pages/my-bids/OffersLost";
import OffersWon from "./pages/my-bids/OffersWon";
import BidlyLiveChat from "./pages/live-chat/BidlyLiveChat";
import NewMessage from "./pages/messages/NewMessage";
import Inbox from "./pages/messages/Inbox";
import Outbox from "./pages/messages/Outbox";
import ViewMessage from "./pages/messages/ViewMessage";
import AgencyRepresentative from "./pages/reg-seller/AgencyRepresentative";
import AgencyOtherInfo from "./pages/reg-seller/AgencyOtherInfo";
import ItemBought from "./pages/my-bids/ItemBought";
import SellerUserNotified from "./pages/reg-seller/SellerUserNotified";
import AuctionItemList from "./pages/seller-listing/AuctionItemList";
import SellerDecision from "./pages/seller-listing/SellerDecision";
import DemoDuration from "./pages/schedule-demo/DemoDuration";
import DemoDate from "./pages/schedule-demo/DemoDate";
import DemoTime from "./pages/schedule-demo/DemoTime";
import BecomeSeller from "./pages/schedule-demo/BecomeSeller";
import DemoDetails from "./pages/schedule-demo/DemoDetails";
import ListingWizard from "./pages/seller-listing/wizard/ListingWizard";
import ScheduleDemoSuccess from "./pages/schedule-demo/ScheduleDemoSuccess";
import AboutUs from "./pages/static/AboutUs";
import ViewBidders from "./pages/seller-listing/ViewBidders";
import Buy from "./pages/static/Buy";
import ContactUs from "./pages/static/ContactUs";
import Help from "./pages/static/Help";
import Articles from "./pages/static/Articles";
import SalesReport from "./pages/seller-reports/SalesReport";
import Dashboard from "./pages/seller-dashboard/Dashboard";
import Privacy from "./pages/static/Privacy";
import ListingInvoice from "./pages/seller-listing/ListingInvoice";
import TermsOfUse from "./pages/TermsOfUse";
import TermsAndConditions from "./pages/registration/TermsAndConditions";
import { isSeller } from "./services/Utilities";
import SellerAccountInformation from "./pages/profile-update/SellerAccountInformation";
import AddAgencyAddress from "./pages/profile-update/AddAgencyAddress";
import AddAgencyPhone from "./pages/profile-update/AddAgencyPhone";
import SellerBankAccountInfo from "./pages/profile-update/SellerBankAccountInfo";
import AddSellerBankInfo from "./pages/profile-update/AddSellerBankInfo";
import SellerTermsInfo from "./pages/profile-update/SellerTermsInfo";
import AddSellerTerms from "./pages/profile-update/AddSellerTerms";
import AllQuestions from "./pages/bidder-questions/AllQuestions";
import QuestionDetails from "./pages/bidder-questions/QuestionDetails";
import ListingPickupConfirm from "./pages/seller-listing/ListingPickupConfirm";
import ItemPickupDetails from "./pages/my-bids/ItemPickupDetails";
import SellerAccountMessage from "./pages/profile-update/SellerAccountMessage";
import ItemBoughtCart from "./pages/my-bids/ItemBoughtCart";
import ItemBoughtCheckout from "./pages/my-bids/ItemBoughtCheckout";
import BidderDashboard from "./pages/bidder-dashboard/BidderDashboard";
import SellerTerms from "./pages/auction/SellerTerms";
import PaymentAwait from "./pages/seller-listing/PaymentAwait";
import ConfirmPayment from "./pages/seller-listing/ConfirmPayment";
import CashierCheque from "./pages/my-bids/CashierCheque";
import UploadCashierCheque from "./pages/my-bids/UploadCashierCheque";
import CashierChequePaymentStatusMessage from "./pages/my-bids/CashierChequePaymentStatusMessage";
import SellerAccountType from "./pages/reg-seller/SellerAccountType";

function App() {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const changeRouteEventHandler = (e) => {
    const { detail: path } = e;
    navigate(path);
  };

  const fetchTopMenuItems = async () => {
    try {
      const countryRs = await LibraryService(COUNTRY_ITEM_COUNT_LIBRARY_URL);
      let countryList = [];
      countryRs?.map((item) => {
        countryList.push({
          key: item.key,
          name: item.value + " (" + item.valueNumber + ")",
        });
      });

      const statesRs = await LibraryService(STATE_ITEM_COUNT_LIBRARY_URL);
      let stateList = [];
      statesRs?.map((item) => {
        stateList.push({
          id: item.id,
          name: item.key + " (" + item.valueNumber + ")",
          country: item.value,
        });
      });

      const citiesRs = await LibraryService(CITY_ITEM_COUNT_LIBRARY_URL);
      let cityList = [];
      citiesRs?.map((item) => {
        cityList.push({
          id: item.id,
          name: item.key + " (" + item.valueNumber + ")",
          state: item.value,
        });
      });

      const catRes = await LibraryService(CATEGORY_MENU_URL);

      ctx.onSetMenuData({
        countries: countryList,
        states: stateList,
        cities: cityList,
        categories: catRes,
      });
    } catch (e) {
      console.log(e);
      navigate("/error");
    }
  };

  useEffect(() => {
    window.addEventListener("changeroute", changeRouteEventHandler);
    return () => {
      window.removeEventListener("changeroute", changeRouteEventHandler);
    };
  }, []);

  useEffect(() => {
    fetchTopMenuItems();
  }, [navigate]);

  const { isAuthorized, viewExpired, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      viewExpired: auth.viewExpired,
      user: auth.user,
    }),
    shallowEqual
  );

  return (
    <>
      {ctx.isShowProgress && <LoadingModal waitImg={Loader} />}

      <Routes>
        <Route path="/categories" element={<AllCategories />} />
        <Route path="/search-result" element={<SearchResult />} />
        <Route path="/advance-search" element={<AdvanceSearch />} />
        <Route path="/" element={<Home />} />

        <Route path="/view-expired" element={<ViewExpired />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="/not-authorized" element={<NotAuthorized />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/too-many-requests" element={<CyberAttack />} />
        <Route
          path="/auction-item/:item"
          element={<AunctionItemPublicView />}
        />
        <Route path="/auction/:item" element={<SingleAunctionItem />} />
        <Route path="/seller-terms/:item" element={<SellerTerms />} />

        <Route path="/demo-duration" element={<DemoDuration />} />
        <Route path="/demo-date" element={<DemoDate />} />
        <Route path="/demo-time" element={<DemoTime />} />
        <Route path="/demo-details" element={<DemoDetails />} />
        <Route path="/demo-success" element={<ScheduleDemoSuccess />} />
        <Route path="/become-seller" element={<BecomeSeller />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/help-and-support" element={<Help />} />
        <Route path="/article/:type" element={<Articles />} />

        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<Privacy />} />

        {isAuthorized && (
          <>
            <Route
              path="/verify-identity-welcome"
              element={<VerifyIdentityWelcome />}
            />
            <Route
              path="/verify-identity-instruction"
              element={<VerifyIdentityInstruction />}
            />
            <Route path="/verify-identity" element={<VerifyIdentity />} />
            <Route
              path="/account-info"
              element={
                (isSeller(user.type) && <SellerAccountInformation />) ||
                (!isSeller(user.type) && <AccountInformation />)
              }
            />
            <Route path="/add-card-info" element={<AddCardInfo />} />
            <Route path="/add-card-billing" element={<AddCardBillingInfo />} />
            <Route path="/add-card" element={<AddCard />} />
            <Route path="/card-setup-complete" element={<AddCardStatus />} />

            <Route path="/open-offers" element={<OpenOffers />} />
            <Route path="/offers-won" element={<OffersWon />} />
            <Route path="/offers-not-won" element={<OffersLost />} />

            <Route path="/open-bids" element={<OpenBids />} />
            <Route path="/bids-won" element={<BidsWon />} />
            <Route path="/bids-not-won" element={<BidsLost />} />

            <Route path="/item-bought-cart" element={<ItemBoughtCart />} />
            <Route
              path="/item-bought-checkout"
              element={<ItemBoughtCheckout />}
            />
            <Route path="/item-bought" element={<ItemBought />} />

            <Route
              path="/item-pickup-detail/:item"
              element={<ItemPickupDetails />}
            />

            <Route
              path="/bid-won-checkout-selection"
              element={<CheckoutSelection />}
            />
            <Route
              path="/bid-card-billing"
              element={<CheckoutCardBillingInfo />}
            />
            <Route
              path="/bid-card-info"
              element={<CheckoutCardPaymentInfo />}
            />
            <Route
              path="/bid-card-payment-complete"
              element={<CardPaymentCompleted />}
            />
            <Route
              path="/bid-card-payment-status"
              element={<CardPaymentStatusMessage />}
            />

            <Route path="/bid-wire-transfer" element={<WireTransfer />} />
            <Route
              path="/bid-wire-receipt-upload"
              element={<UploadWireReceipt />}
            />
            <Route
              path="/bid-wire-payment-status"
              element={<WirePaymentStatusMessage />}
            />

            <Route path="/bid-cashier-cheque" element={<CashierCheque />} />
            <Route
              path="/bid-cashier-cheque-upload"
              element={<UploadCashierCheque />}
            />
            <Route
              path="/bid-cashier-cheque-payment-status"
              element={<CashierChequePaymentStatusMessage />}
            />

            <Route path="/view-message" element={<ViewMessage />} />
            <Route path="/new-message" element={<NewMessage />} />
            <Route path="/inbox" element={<Inbox />} />
            <Route path="/outbox" element={<Outbox />} />

            <Route path="/listing-new" element={<ListingWizard />} />

            <Route path="/my-listing" element={<AuctionItemList />} />
            <Route path="/listing-award/:item" element={<SellerDecision />} />
            <Route
              path="/listing-payment-await/:item"
              element={<PaymentAwait />}
            />
            <Route
              path="/listing-confirm-payment/:item"
              element={<ConfirmPayment />}
            />
            <Route path="/listing-bidders/:item" element={<ViewBidders />} />

            <Route path="/listing-invoice/:item" element={<ListingInvoice />} />
            <Route
              path="/listing-pickup-confirm/:item"
              element={<ListingPickupConfirm />}
            />

            <Route path="/bidder-questions" element={<AllQuestions />} />
            <Route path="/question-details" element={<QuestionDetails />} />

            <Route path="/sales-report" element={<SalesReport />} />

            <Route path="/my-dashboard" element={<Dashboard />} />
            <Route path="/bidder-dashboard" element={<BidderDashboard />} />

            <Route path="/add-address-info" element={<AddAddressInfo />} />
            <Route path="/add-address" element={<AddAddress />} />
            <Route path="/add-agency-address" element={<AddAgencyAddress />} />
            <Route path="/add-phone-info" element={<AddPhoneInfo />} />
            <Route path="/add-phone" element={<AddPhone />} />
            <Route
              path="/apply-for-bidder-account"
              element={<SellerAccountMessage />}
            />

            {false && (
              <>
                <Route
                  path="/seller-bank-info"
                  element={<SellerBankAccountInfo />}
                />
                <Route
                  path="/add-seller-bank-info"
                  element={<AddSellerBankInfo />}
                />
              </>
            )}

            <Route path="/seller-terms-info" element={<SellerTermsInfo />} />
            <Route path="/add-seller-terms" element={<AddSellerTerms />} />

            <Route path="/add-agency-phone" element={<AddAgencyPhone />} />
            <Route path="/wish-list" element={<WishList />} />
            <Route path="/reset-password-pu" element={<ResetPasswordPU />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="*" element={<Navigate to="/404" />} />
          </>
        )}

        {!isAuthorized && (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/account-type" element={<AccountType />} />
            <Route path="/personal-info" element={<PersonalInformation />} />
            <Route path="/user-notified" element={<UserNotified />} />
            <Route path="/reg-success" element={<RegistrationSuccessfull />} />
            <Route path="/verify" element={<VerifyLink />} />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/forgot-password/verify" element={<VerifyLinkFP />} />
            <Route path="/logout" element={<Navigate to="/" />} />

            <Route
              path="/reprentative-info"
              element={<AgencyRepresentative />}
            />
            <Route
              path="/seller-account-type"
              element={<SellerAccountType />}
            />
            <Route path="/agency-other-info" element={<AgencyOtherInfo />} />
            <Route path="/agency-notified" element={<SellerUserNotified />} />
            <Route path="/become-seller" element={<BecomeSeller />} />
            <Route path="/demo-details" element={<DemoDetails />} />
            <Route path="/terms" element={<TermsAndConditions />} />

            <Route
              path="*"
              element={
                viewExpired ? <Navigate to="/" /> : <Navigate to="/404" />
              }
            />
          </>
        )}
      </Routes>

      <BidlyLiveChat />
    </>
  );
}

export default App;
